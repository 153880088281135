export const useNotifications = () => {
	interface Notification {
		id: string
		mensagem: string
		data: string
		lida: boolean
	}

	const { data: notifications, refresh, status } = useAPI<Notification[]>('/notificacoes', {
		method: 'GET'
	})

	const hasUnreadNotifications = computed(() => {
		return notifications.value?.some(notification => !notification.lida) ?? false
	})

	// Function to mark notification as read
	const markAsRead = async (notificationId: string) => {
		try {
			await useAPI(`/notificacoes/${notificationId}/lida`, {
				method: 'PUT'
			})

			// Force update the notification state if HTTP status is 200
			if (notifications.value) {
				const notification = notifications.value.find(n => n.id === notificationId)
				if (notification) {
					notification.lida = true
				}
			}
		} catch (error) {
			console.error('Error marking notification as read:', error)
		}
	}

	return {
		notifications,
		hasUnreadNotifications,
		refresh,
		status,
		markAsRead
	}
}
